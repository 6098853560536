import * as THREE from 'three'

import React, { Suspense, useRef, useState, useEffect, useCallback,lazy } from 'react';
import { Canvas, extend, useThree, useFrame } from '@react-three/fiber';
import { Bounds, Environment, OrbitControls, } from '@react-three/drei';
//import { Canvas, extend,  useFrame } from 'react-three-fiber'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import { ACESFilmicToneMapping, CineonToneMapping, LinearToneMapping, sRGBEncoding } from 'three';
import {
  Asset,
  DisplayAttribute,
  Spinner,
  ThreekitSource,
  ThreekitCanvas
} from '@threekit/lignin';
import Configurator from './Configurator';
import Button from '@mui/material/Button';
import ReactFullpage from '@fullpage/react-fullpage';
import Header from './Header'
import { isMobile } from 'react-device-detect';
const AnimateAsset = lazy(() => import("./AnimateAsset"))
// import Effects from './Effects' 


extend({ EffectComposer, ShaderPass, RenderPass, UnrealBloomPass, SSAOPass })



const whoopAuthToken = 'a1cfba75-8b95-4271-bf62-e0640079666e';



const Arrow = function () { return <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" /></svg> }

//<unrealBloomPass attachArray="passes" args={[undefined, 1.6, 1, 0.95]} />
//      <shaderPass attachArray="passes" args={[FXAAShader]} material-uniforms-resolution-value={[1 / size.width, 1 / size.height]} />
//      
//<shaderPass attachArray="passes" args={[FXAAShader]} material-uniforms-resolution-value={[1 / size.width, 1 / size.height]} renderToScreen />
// 
//


// Lights
function KeyLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={3}
      height={3}
      color={color}
      intensity={brightness}
      position={[-2, 0, 5]}
      lookAt={[0, 0, 0]}
      penumbra={1}
      castShadow
    />
  );
}
function FillLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={3}
      height={3}
      intensity={brightness}
      color={color}
      position={[2, 1, 4]}
      lookAt={[0, 0, 0]}
      penumbra={2}
      castShadow
    />
  );
}

function RimLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={2}
      height={2}
      intensity={brightness}
      color={color}
      position={[1, 4, -2]}
      rotation={[0, 180, 0]}
      castShadow
    />
  );
}

export default function Whoop({ animate, assetId, pages, links, translations, rotations, parentScale}) {
  const [position, setPosition] = useState(0);
  const [attrs, setAttrs] = useState([]);
  const [fullPageApi, setFullPageApi] = useState();

  useEffect(() => {
  }, [attrs]);

  const ref = useRef();
  // const scroll = useScroll()


  const [configuration, setConfiguration] = useState({});
  // console.log('attrs', attrs, animate);
  // useTransition from react 18. Not required?
  // const [isPending, startTransition] = useTransition({ timeoutMs: 2000 });

  function onChange(conf) {
    setConfiguration({ ...configuration, ...conf });
  }

  const background = {
    background:
      'radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)',
  };

  function handleNav(e) {
    setPosition(e.target.value)
  }
 
  function generateSections() {
    let arr = []
    for (let i = 0; i < pages; i++) {
      arr.push(<div className="section" style={{
        alignItems: i%2 ? "start":"end"
      }}>
        <div id={`portal-${i+1}`} style={{
          margin: "50px",
          position: "absolute",
          width: "fit-content"
        }}>
        </div>
      </div>)
    }
    return arr
  } 

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        // ...(animate ? background : {}),
      }}
    >
      <Suspense fallback={null}>
      <ThreekitSource env="preview" token={whoopAuthToken} org='420d0a9e-ecad-46b9-9449-d05e08fc3958'>
        <div id="canvas-container" style={{ position: "fixed", height: "100vh", width: "100vw" }}>
          <ThreekitCanvas mode="concurrent" colorManagement onCreated={({ gl }) => { gl.toneMapping = ACESFilmicToneMapping; gl.toneMappingExposure = 0.8; gl.outputEncoding = sRGBEncoding; }}>
            {/* <Suspense fallback={null}> */}
              <Bounds fit clip margin={1.5} observe>
                <group ref={ref}>
                  <AnimateAsset
                    attrs={attrs}
                    setAttrs={setAttrs}
                    configuration={configuration}
                    onChange={onChange}
                    position={position}
                    setPosition={setPosition}
                    fullPageApi={fullPageApi}
                    assetId={assetId}
                    translations={translations}
                    rotations={rotations}
                  parentScale={parentScale}
                  />
                  {/* <Asset
          id={whoopAssetId}
          onAttributes={setAttrs}
          configuration={configuration}
        /> */}
                </group>
              </Bounds>
            {/* </Suspense> */}
            <Environment files="https://solutions-engineering.s3.amazonaws.com/studio_small_09_1k.hdr" />
            <KeyLight brightness={3} color={"#ffffef"} />
            <FillLight brightness={3} color={"#ffefff"} />
            <RimLight brightness={3} color={"#fff"} />
            {/* <Effects /> */}
            {/* <OrbitControls object={ref} makeDefault /> */}

          </ThreekitCanvas>
        </div>
        {!animate && <Configurator assetId={assetId} attrs={attrs} onChange={onChange} />}
      </ThreekitSource>
      </Suspense>
      <ReactFullpage
        paddingTop='50px'
        afterLoad={useCallback(
          () => {
            if (fullPageApi) {
              if (fullPageApi.getActiveSection().index() == 3) {
                document.getElementsByClassName("circle")[0].classList.add("show")
              } else {
                document.getElementsByClassName("circle")[0].classList.remove("show")
              }
            }

          },
          [], // Tells React to memoize regardless of arguments.
        )}
        //fullpage options
        licenseKey={'YOUR_KEY_HERE'}
        scrollingSpeed={1000} /* Options here */

        render={({ state, fullpageApi }) => {
          if (fullpageApi && fullpageApi.getActiveSection().index() == 0) {
            document.getElementById("bodyId").style.transition = "all .5s ease"
            document.getElementById("bodyId").style.backgroundColor = "#ffffff"
          }
          else if (fullpageApi && fullpageApi.getActiveSection().index() == 1) {
            document.getElementById("bodyId").style.transition = "all .5s ease"
            document.getElementById("bodyId").style.backgroundColor = "#d2dffe"
          } else if (fullpageApi && fullpageApi.getActiveSection().index() == 2) {
            document.getElementById("bodyId").style.transition = "all .5s ease"
            document.getElementById("bodyId").style.backgroundColor = "#ffd7cf"
          } else if (fullpageApi && fullpageApi.getActiveSection().index() == 3) {
            document.getElementById("bodyId").style.transition = "all .5s ease"
            document.getElementById("bodyId").style.backgroundColor = "#d7dcf7"
          }
          setFullPageApi(fullpageApi)
          if (fullpageApi) {
            if (fullpageApi.getActiveSection().index() == pages) {
              document.getElementsByClassName("circle")[0].classList.add("show")
            } else {
              document.getElementsByClassName("circle")[0].classList.remove("show")
            }
          }
          
          return (
            <ReactFullpage.Wrapper>
              {generateSections()}
              <div className="section" style={{
                flexDirection: "row !important",
                justifyContent: "space-between !important"
              }}>
                <div class="circle">
                  <div class="content"></div>
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "end"
                }}>
                  {links.vto ? <Button variant="outlined" href={links.vto} target="_blank">
                    Threekit Try On
                  </Button> :null}
                  {links.instagram ? <Button variant="outlined" href={links.instagram} target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg>
                      Try On
                  </Button>: null}
                  {links.snapchat? <Button variant="outlined" href={links.snapchat} target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5.829 4.533c-.6 1.344-.363 3.752-.267 5.436-.648.359-1.48-.271-1.951-.271-.49 0-1.075.322-1.167.802-.066.346.089.85 1.201 1.289.43.17 1.453.37 1.69.928.333.784-1.71 4.403-4.918 4.931-.251.041-.43.265-.416.519.056.975 2.242 1.357 3.211 1.507.099.134.179.7.306 1.131.057.193.204.424.582.424.493 0 1.312-.38 2.738-.144 1.398.233 2.712 2.215 5.235 2.215 2.345 0 3.744-1.991 5.09-2.215.779-.129 1.448-.088 2.196.058.515.101.977.157 1.124-.349.129-.437.208-.992.305-1.123.96-.149 3.156-.53 3.211-1.505.014-.254-.165-.477-.416-.519-3.154-.52-5.259-4.128-4.918-4.931.236-.557 1.252-.755 1.69-.928.814-.321 1.222-.716 1.213-1.173-.011-.585-.715-.934-1.233-.934-.527 0-1.284.624-1.897.286.096-1.698.332-4.095-.267-5.438-1.135-2.543-3.66-3.829-6.184-3.829-2.508 0-5.014 1.268-6.158 3.833z" fill="#020202" /></svg>
                      Try On
                  </Button>: null}
                   <Button variant="outlined" href={"/"}>
                    Other Products
                  </Button>
                </div>
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
      {animate}
    </div>
  );
}
//
//<Environment files="./studio_small_09_1k.hdr" />
//         <KeyLight brightness={8} color={"#ffffef"} />
//<FillLight brightness={6} color={"#ffefff"} />
//<RimLight brightness={10} color={"#fff"} />
//  <KeyLight brightness={10} color={"#ffffef"} />
//<FillLight brightness={8} color={"#ffefff"} />
//<RimLight brightness={10} color={"#fff"} />


